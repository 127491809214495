//时间格式
Date.prototype.toDate = (time)=> {
    var date = new Date(time*1000);
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
    var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    var h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    var m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    return M+'-'+D+' '+h + ':' + m;
  }
Date.prototype.toMatchDate = (time)=> {
    var date = new Date(time*1000);
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
    var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
	var h = date.getHours();
    var m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    return  M+'/'+D+' '+ h + ':' + m;
  }
Date.prototype.toNewDate = (time)=> {
    var date = new Date(time*1000);
    var Y = date.getFullYear();
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
    var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    var h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    var m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    return  `${Y}/${M}/${D}  ${h}:${m}`;
  }
Date.prototype.toCreateDate = (time)=> {
    var date = new Date(time*1000);
    var Y = date.getFullYear();
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
    var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    var h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    var m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    return  `${Y}-${M}-${D}  ${h}:${m}`;
  }

  Date.prototype.toaddtime = (time)=> {
    const now = new Date();
    const targetTime = new Date(time*1000);

    const diff = now - targetTime;

    // 转换为分钟
    const minutes = Math.floor(diff / (1000 * 60));
    if (minutes < 60) {
      return minutes + "分钟前";
    }
  
    // 转换为小时
    const hours = Math.floor(diff / (1000 * 60 * 60));
    if (hours < 3) {
      return hours + "小时前";
    }

    // 判断是否为今天
    const today = new Date();
    if (targetTime >= today.setHours(0, 0, 0, 0) && targetTime < today.setHours(23, 59, 59, 999)) {
      return "今天 " + formatDate(targetTime, "HH:mm");
    }

    // 判断是否为昨天
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    if (targetTime >= yesterday.setHours(0, 0, 0, 0) && targetTime < yesterday.setHours(23, 59, 59, 999)) {
      return "昨天 " + formatDate(targetTime, "HH:mm");
    }
  
    // 默认返回完整日期和时间
    return formatDate(targetTime, "yyyy-MM-dd HH:mm");
  }


  function formatDate(timestamp, format) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
  
    format = format.replace("yyyy", year);
    format = format.replace("MM", month);
    format = format.replace("dd", day);
    format = format.replace("HH", hours);
    format = format.replace("mm", minutes);
  
    return format;
  }
