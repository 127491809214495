import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import global from "@/components/globalVariable"
import Base64 from '@/util/base64.js'
import 'default-passive-events'
import jsCookie from 'js-cookie'
import introJs from 'intro.js' // introjs库
import 'intro.js/introjs.css' // introjs默认css样式
// introjs还提供了多种主题，可以通过以下方式引入
import 'intro.js/themes/introjs-modern.css' // introjs主题
const intro = introJs()
intro.setOptions({
	nextLabel: '下一步',  // 下一个按钮文字
	prevLabel: '',  // 上一个按钮文字
	// skipLabel: '跳过',    // 跳过按钮文字
	// doneLabel: '完成',// 完成按钮文字
	hidePrev: true,       // 在第一步中是否隐藏上一个按钮
	// hideNext: true,       // 在最后一步中是否隐藏下一个按钮
	doneLabel: '下一步',// 完成按钮文字
	hidePrev: true,    
	exitOnOverlayClick: false,  // 点击叠加层时是否退出介绍
	showStepNumbers: false,     // 是否显示红色圆圈的步骤编号
	disableInteraction: true,   // 是否禁用与突出显示的框内的元素的交互，就是禁止点击
	showBullets: false          // 是否显示面板指示点
	  });
Vue.prototype.$intro = intro;
import VueNativeSock from 'vue-native-websocket'
Vue.use(VueNativeSock, 'wss://data.yingtiyu.com:8281/ws/',{
	reconnection: true,
	reconnectionAttempts: 5,
	reconnectionDelay: 1000
})

Vue.use(ElementUI).use(VueAxios, axios);


//调用友好时间函数
require('@/util/TimeStamp')

Vue.config.productionTip = false;
Vue.prototype.$cookie = jsCookie;
Vue.prototype.GLOBAL = global;
Vue.prototype.$Base64 = Base64;



new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
  // 预渲染
  mounted () {
    document.dispatchEvent(new Event('render-event'))
	// document.dispatchEvent(new Event("custom-render-trigger"))
  }
})
// .$mount('#app')
