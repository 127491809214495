import Vue from 'vue'
import Vuex from 'vuex'
import userInfoModule from "./userInfo";

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		userInfoModule
	},
	state:{
		isNav:false
	}
})