export default {
    state(){
        return {
            userData:{}
        }
    },
    mutations:{
        getUserInfo(state,e){
            state.userData = e
            // console.log(state.userData);
        },
    },
    actions:{
        getUserInfo(context,e){
            context.commit('getUserInfo', e)
        }
    },
    getters:{
       
    }
}