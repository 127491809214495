import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/views/Login.vue";
import page from '@/router/page'
import http from "@/api/http.js";
import store from '@/store/index.js'
import jsCookie from 'js-cookie'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'home',
		redirect: '/firstPage'
	},
	{
		path: "/postBar",
		name: "PostBar",
		// redirect: '/firstPage',
		component: () => import('@/components/postBar/PostBar.vue')
	},
	{
		path: "/manageTieba",
		name: "ManageTieba",
		// redirect: '/firstPage',
		component: () => import('@/components/postBar/ManageTieba.vue')
	},
	{
		path: "/postTopic",
		name: "PostTopic",
		component: () => import('@/components/postBar/PostTopic.vue')
	},
	{
		path: "/articlePage",
		name: "ArticlePage",
		meta:{
			seotitle:'足球方案_篮球方案-赢体育',
			keywords:'足球方案,篮球方案',
			description:'赢体育，为您提供海量高命中的足球方案和篮球方案，让您的竞彩一触即发！足彩篮彩竞彩专家比分预测平台'
		},
		component: () => import('@/components/postBar/ArticlePage.vue')
	},
	{
		path: "/gameTieba",
		name: "GameTieba",
		component: () => import('@/components/postBar/GameTieba.vue')
	},
	{
		path: "/tiebaDetails",
		name: "TiebaDetails",
		component: () => import('@/components/postBar/TiebaDetails.vue')
	},
	{
		path: "/pagination",
		name: "Pagination",
		component: () => import('@/components/Pagination.vue')
	},{
		path: "/userHome",
		name: "UserHome",
		meta:{
			seotitle:'足球专家_篮球专家-赢体育',
			keywords:'足球专家,篮球专家',
			description:'赢体育，为您提供海量高强足球专家和篮球专家，让您的竞彩一触即发！足彩篮彩竞彩专家比分预测平台'
		},
		component: () => import('@/components/UserHome.vue')
	},{
		path: "/questionnaire",
		name: "Questionnaire",
		component: () => import('@/components/Questionnaire.vue')
	},{
		path: "/recharge",
		name: "Recharge",
		component: () => import('@/components/Recharge.vue')
	},
	{
		path: "/withdrawal",
		name: "Withdrawal",
		component: () => import('@/components/Withdrawal.vue'),
	},
	{
		path: "/rankingRules",
		name: "RankingRules",
		component: () => import('@/components/balls/RankingRules.vue'),
	},
	{
		path: "/aboutUs",
		name: "AboutUs",
		component: () => import('@/components/AboutUs.vue')
	},
	{
		path: "/member",
		name: "Member",
		component: () => import('@/components/Member.vue')
	},
  {
  	path: "/zsvip",
  	name: "Zsvip",
  	component: () => import('@/components/Zsvip.vue')
  },
	...page,
	{
		path: "/login",
		name: "Login",
		component: () => import('@/views/Login.vue'),
    meta: {
            title: '用户登录_赢体育_足球比分'
          }
	},
	{
		path: "/forgotPwd",
		name: "ForgotPwd",
		component: () => import('@/views/ForgotPwd.vue'),
		meta: {
	        title: '忘记密码_赢体育_足球比分'
	      }
	},
	{
		path: "/register",
		name: "Register",
		component: () => import('@/views/Register.vue'),
    meta: {
            title: '关于我们_赢体育_足球比分'
          }
	},
	{
		path: '/about',
		name: 'AboutView',
		component: () => import('@/views/AboutView.vue')
	},
	{
	  path: "/404",
	  name: "404",
	  component: () => import('@/views/404.vue')
	},
	{
	  path: "/:path(.*)+",
	  name: "404view",
	  redirect:'/404'
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

import { Message as $msg } from 'element-ui'; //引用并取别名
// import store from '@/store'
//前置路由守卫
 router.beforeEach((to,from,next)=>{
	 // console.log(store.state.isNav,88);
	 // console.log(to.path.indexOf('personalCenter'),11);
	 store.state.isNav = false
	if (to.name=='FirstPage'||to.name=='GuessBall'||to.name=='Tieba'||to.name=='Expert'||to.path.indexOf('personalCenter')!=-1) {
		store.state.isNav = to.name
	}else {
		store.state.isNav = null
	}
	// console.log(store.state.isNav,99);
	//console.log(to.meta);
  	window.document.title = (to.meta.seotitle == undefined) ? '赢球江湖_赢体育' : to.meta.seotitle;
  if (to.name=='PostTopic'||to.name=='MyAccount'||to.name=='MyAttention'||to.name=='MySect'||to.name=='MyPublication'||to.name=='MyGuessBall'
  ||to.name=='Recharge'||to.name=='Withdrawal'||to.name=='Questionnaire'||to.name=='Member'||to.name=='Alipay') {
	  // store.state.isNav = true
	//  判断是否有token
	let token = (localStorage.getItem('token')) ? localStorage.getItem('token') : jsCookie.get('token', { domain: '.yingtiyu.com' });;
	if(token==''||token==null||token==undefined){
		$msg('请先登录！');
		next({name:'Login'})
		return
	}
	http.post('login/checkToken',{token:token}).then(res=>{
		if (res.data.code!=90001) {
			$msg.warning(' 用户信息超时,请登录！');
			// store.dispatch('getUserInfo',{})
			localStorage.clear();
			next({name:'Login'})
		}else{
			let userData = res.data.data.useInfo
			localStorage.setItem('userInfo',JSON.stringify(userData));
			localStorage.setItem('token',userData.token);
			next()
		}
	})
  } else if (to.name=='FootBall'||to.name=='BasketBall') {
		window.open('http://vip.yingtiyu.com/bf/',"_blank")
		next({name:from.name})
	} else{
		next()
	}

})

export default router
