<script>
	import base_url from "@/api/baseUrl";
	const linkBaseUrl = 'http://neiye.90vs1.com:3389'; //析欧亚大base地址
	const baseUrl = 'https://www.yingtiyu.com'
	const socreUrl = 'https://home.90vs.com/js'

	export default {
		linkBaseUrl,
		base_url,
		baseUrl,
	}
</script>