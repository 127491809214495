<template>
  <div id="app">
    <!-- <router-view/> -->
		<div class="home">
			<el-container>
				<el-header height="70px" v-if="isShow!='false'">
					<div class="headerBox">
						<div class="homeLogo">
							<img src="@/static/img/indexLogo.png" alt="" @click="toFirst">
						</div>
						<div class="homeTags">
							<span v-for="(navItem,navIndex) in navList" :key="navIndex" :class="navIndex==newIndex?'isNav':''" @click="nacCli(navIndex)">
								<router-link :to="navItem.path" v-if="newIndex==0" :data-intro="stepText[navIndex]" :data-step="++navIndex">{{navItem.meta.title}}</router-link>
								<router-link :to="navItem.path" v-else>{{navItem.meta.title}}</router-link>
								<a v-if="userInfo"><el-badge v-if="userInfo.msgNum!=0&navIndex==6" is-dot class="item"></el-badge></a>
							</span>
						</div>
						<div class="index_right">
							<div v-if="userStatus" class="userBtn">
								<span @click="hideLayer">欢迎您：<a>{{userInfo.username}} &nbsp;<i class="el-icon-caret-bottom"></i></a></span>
							</div>
							<div v-if="!userStatus" class="handleBtn" @click="toLogin">
								<span>登陆</span><span><el-button size="mini" round>注册</el-button></span>
							</div>
						</div>
						<div class="userK" v-if="userInfo" v-show="showLayer" @mouseleave="hideLayer"><!--@mouseleave="hideLayer"-->
							<div class="userKTitle">
								<span  @click="toMember"><i class="el-icon-warning"></i>&nbsp;优惠活动--新上线，详细请点击以下会员&nbsp;>></span>
							</div>
							<div class="userKInfo">
								<div class="userKImg"><img class="Avatar" :src="userInfo.thumb?pubImg(userInfo.thumb):require('@/static/img/defaultAvatar.png')" alt=""></div>
								<div class="userKName">
									<div>{{userInfo.username}}</div>
									<div>赢体育号: Y{{userInfo.id}}</div>
								</div>
								<div class="userCentBtn" @click="toPersonal"><el-button size="mini" round>个人中心</el-button></div>
							</div>
              <div class="zsvipCenter">
                  <div class="zsvipLeft">
                    <div class="vipTitle">
                      比分会员
                    </div>
                    <div class="futitle">
                      ·全面数据 一网打尽
                    </div>
                  </div>
                  <div class="zsvipRight">
                      <div class="vipBtn" @click="toZs" v-if="userInfo.zsvipTime>0">
                        打开VIP比分
                      </div>
                      <div class="vipBtn" @click="toZsvip" v-if="userInfo.zsvipTime==0">
                        购买VIP
                      </div>
                      <div class="futitleL">
                        剩余天数：{{ userInfo.zsvipTime }}天
                      </div>
                  </div>
              </div>
              <div class="vipCenter">
                <div class="zsvipCenter zj">
                    <div class="zsvipLeft">
                      <div class="vipTitle zj">
                        专家会员
                      </div>
                      <div class="futitle">
                        ·专业分析 一览无遗
                      </div>
                    </div>
                    <div class="zsvipRight">
                        <div class="vipBtn zj" @click="toMember">
                          购买VIP
                        </div>
                        <div class="vipBtn zj" @click="toRecharge">
                          充值Y币
                        </div>
                    </div>
                </div>
                <div class="userKCoin">
                	<div>{{ userInfo.amountAll }}<p>Y币/个</p></div>
                	<el-divider color="#B6CBF0" direction="vertical"></el-divider>
                	<div>{{ userInfo.couponsCount }}<p>方案券/张</p></div>
                	<el-divider color="#B6CBF0" direction="vertical"></el-divider>
                	<div>{{ userInfo.couponsTime }}<p>会员/天</p></div>
                </div>
              </div>
							<div class="userExit">
								<div @click="outLogin"><span>退出登录</span></div>
							</div>
						</div>
					</div>

				</el-header>
				<el-main :style="isShow=='false'?'margin-top: 0;':''"><router-view ref="child" :ruleTipLists="currentTabList" @changeActiveStep="changeStep"  @changeIsShow="IsShow" @changeUser="getUser" @changeWizard="isWizard" @ruleShowCli="ruleShowX" /></el-main>
				<el-footer v-if="isShow!='false'">
					<div>友情链接：</div>
					<div class="alinks">
						<span v-for="(footItem,footIndex) in linkList" :key="footIndex">
							<a @click="toLink(footItem.urls)">{{footItem.name}}</a>
							<el-divider color="#333333"  direction="vertical"></el-divider>
						</span>
					</div>
					<div><span class="about" @click="toAboutUs">关于我们</span><a style="color:#ffffff" href="http://beian.miit.gov.cn/" target="_blank">粤ICP备18043265号-8</a>Copyright © 2002-2023 讯天科技</div>
					<div>声明：本网资讯仅供体育爱好者浏览、购买中国足彩参考之用。任何人不得用于非法用途，否则责任自负。本网所登载广告均为广告客户的个人意见及表达方式，和本网无任何关系。</div>
					<div>链接的广告仅限于体育或中国足彩推荐信息、不得含有色情、政治或博彩信息，如有违反国家法律规定的，本网有权随时予以删除，并保留与有关部门合作追究的权利。</div>
				</el-footer>
			</el-container>
		</div>
		<div class="ruleShow">
			<el-dialog
			  :visible.sync="isRuleShow"
			  :close-on-click-modal="false"
			  width="500px">
			  <img src="@/static/img/questShow.png" @click="toQuest" style="width: 500px;" alt="">
			</el-dialog>
		</div>
		<div class="ruleShow isRuleTopShow">
			<el-dialog
			  :visible.sync="isRuleShowR"
			  width="600px">
			  <div v-html="ruleVal"></div>
			  <span slot="footer" class="dialog-footer">
			    <el-button type="primary" @click="isRuleShowR = false">关 闭</el-button>
			  </span>
			</el-dialog>
		</div>
		<div class="ruleShow isRuleTopShow">
			<el-dialog
			  :visible.sync="isRuleTopShow" width="600px">
			  <div v-html="isRuleTopInd?rule2Val:rule1Val"></div>
			  <span slot="footer" class="dialog-footer">
			    <el-button type="primary" @click="isRuleTopShow = false">关 闭</el-button>
			  </span>
			</el-dialog>
		</div>
		<div class="returnTop"  v-if="isShow!='false'">
			<div @click="clickBackTop" v-show="scrollTopCount > 50" title="回到顶部"><img src="@/static/icons/backTop.png" alt=""></div>
			<div title="联系方式"><img src="@/static/icons/contact.png" alt=""><div style="display: none;" class="contactCode"><img src="@/static/img/contactCode.png" alt=""></div></div>
			<!--<div title="Y猜球查看方案规则" @click="isRuleTopCli(0)"><img src="@/static/icons/rule1.png" alt=""><div style="display: none;" class="contactCode"><img src="@/static/img/contactCode.png" alt=""></div></div>
			<div title="Y猜球排行榜规则" @click="isRuleTopCli(1)"><img src="@/static/icons/rule2.png" alt=""><div style="display: none;" class="contactCode"><img src="@/static/img/contactCode.png" alt=""></div></div>-->
		</div>
  </div>
</template>

<script>
	import page from '@/router/page'
	import http from "@/api/http.js";
import { version } from 'websocket';
	// import { mapState } from "vuex";
	// import { mapActions } from "vuex";

	export default {
		data() {
			return {
				isRuleShow:false,
			ruleVal:'',
				ruleForm: {
					username: '',
					password: '',
					AuthCode: ''
				},
				codeText: '获取验证码',
				codeTimes: 300,
				linkList:[],
				userStatus:false,
				isShow:'true',
				newIndex:null,
				stepText:['江湖里有最新的赛事资讯、网友赛事分析和实力最强的门派推荐',
				'门派在江湖里各自为营，聚集各路高手，对赛事有独特的见解','足球赛事即时比分',
				'有江湖就有擂台，来对赛事竞猜，看看你的实力',
				'关于个人账户的设置都在这里'],
				userInfo:JSON.parse(localStorage.getItem('userInfo')),
				scrollTopCount: document.documentElement.scrollTop, // 监听#el-main滚动距离顶部的高度
				isRuleTopShow:false,
				rule1Val:'<p><strong>Y猜球查看方案规则</strong></p><p>1.	可通过账户充值购买Y币，20Y币查看一场高手方案</p><p>2.	累计的查看球币，赛后结算作为被查看者奖励</p>',
				rule2Val:"<h1>Y猜球排行榜规则</h1><p>●&nbsp;&nbsp;<strong>足球周榜</strong></p><p>周榜统计当天起前7日的方案，需发布22场以上（不含22场），按命中率排行，连续三天没发布的用户将会掉出排行榜，重新发布可恢复排行。</p><p>●&nbsp;&nbsp;排名第一 奖金500元宝</p><p>●&nbsp;&nbsp;排名第二 奖金200元宝</p><p>●&nbsp;&nbsp;排名第三 奖金100元宝</p><p>注：奖励以Y币形式发放，周命中率低于65%奖金减半，当周连续3天没发布的用户取消领奖资格。</p><p>●&nbsp;&nbsp;<strong>足球月榜</strong></p><p><span class=\"ql-cursor\">﻿</span>月榜统计当天起前30日的方案，需发布60场以上（不含60场），按命中率排行，连续五天没发布的用户将会掉出排行榜，重新发布可恢复排行。</p><p>●&nbsp;&nbsp;排名第一 奖金800元宝</p><p>●&nbsp;&nbsp;排名第二 奖金500元宝</p><p>●&nbsp;&nbsp;排名第三 奖金300元宝</p><p>注：奖励以Y币形式发放，月命中率低于65%奖金减半，当月连续5天没发布的用户取消领奖资格。</p><p>●&nbsp;&nbsp;<strong>足球季榜</strong></p><p>季榜统计当天起前90日的方案，需发布180场以上（不含180场），按命中率排行，连续五天没发布的用户将会掉出排行榜，重新发布即可恢复排行。</p><p>●&nbsp;&nbsp;<strong>足球连红榜</strong></p><p>连红榜统计当天起前7日的方案，连续7天没发布将重置连红次数；若达10连红时间相同，则当周命中率越高排名越前；达6连红符合上榜资格，达8连红可展示出高手推荐页。</p>",
				isRuleTopInd:0,
				isRuleShowR:false,
				ruleTips:[],
				currentTabList:['新注册会员将赠送3天VIP体验卡(10次方案查看,历史方案免费查看)','在赢体育获得的收益，在缴纳8%个人所得税后，用户最高可获得85%的分成！'],
				versions:new Date().getTime(),
				showLayer:false,
			}
		},
		computed: {
			// ...mapState(["isLoading"]),
			navList() {
				return {
					...page
				}
			},
			pubImg(){
				return (thumb)=>{
					if (thumb.indexOf('https')!=-1) {
						return thumb
					} else{
						return (this.GLOBAL.baseUrl+thumb)
					}
				}
			},
			couponsText(){
				return (coupons)=>{
					switch(coupons){
						case '1':
							return '周卡'
							break;
						case '2':
							return '月卡'
							break;
						case '3':
							return '年卡'
							break;
						case '4':
							return '体验卡'
							break;
					}
				}
			},
			// userStatus(){
			// 	let n = localStorage.getItem('userStatus')
			// 	console.log(n);
			// 	return n?n:'false'
			// 	// let userInfo = JSON.parse(localStorage.getItem('userInfo'))
			// 	// return userInfo
			// },
			// isShow(){
			// 	let m = localStorage.getItem('isShow')
			// 	return m
			// }
			// userInfo(){
			// 	if (localStorage.getItem('userInfo')==null) return
			// 	return JSON.parse(localStorage.getItem('userInfo'))
			// }
		},
		created() {
			// console.log(this.$route.name);
			// if (this.$route.name=='Login'||this.$route.name=='Register'||this.$route.name=='AboutView'||this.$route.name=='404'||this.$route.name==null) {
			// 	this.isShow = false
			// }else {
			// 	this.isShow = true
			// }
			this.getRuleTip()
			this.getNav()
			this.userStatus = this.userInfo?true:false;
			this.getLink();
			// let token = localStorage.getItem('token')
			// if (token!==''&&token!=null&&token!=undefined) {
			// 	let userInfo = JSON.parse(localStorage.getItem('userInfo'))
			// 	if(userInfo.isask!=1&&window.location.pathname!='/questionnaire'&&window.location.pathname!='/login'&&window.location.pathname!='/forgotPwd'&&window.location.pathname!='/register'){
			// 		this.isRuleShow = true;
			// 	}
			// }
			// let num = 0;
			// let isnMsg = localStorage.getItem('isnMsg');
			// // console.log(isnMsg,77)
			// if (isnMsg) {
			// 	num = Number(isnMsg);
			// }
			// num+=1;
			// console.log(num,888);
			// localStorage.setItem('isnMsg',num)
			// isnMsg = localStorage.getItem('isnMsg')
			// // (isnMsg>0 && isnMsg%5==0)||isnMsg==1
			// if (isnMsg==1) {
			// 	this.ruleShow()
			// }else {
			// 	this.isRuleShow = false;
			// }
			// localStorage.setItem('isnMsg',1);
			// this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
		},
		mounted() {
			window.addEventListener('scroll', this.handleScroll, true)
			// let isnMsg = localStorage.getItem('isnMsg')
			// if (isnMsg!=1||(i>0 && i%5!=0)) {
			// 	this.isRuleShow = false;
			// }
			this.$emit('updateVersion',this.versions);

		},
		watch: {
			'$store.state.isNav' (newValue, oldValue) {
			// console.log('count changed:', newValue);
				this.getNav()
			}
		},
		methods: {
			// ...mapActions(['getUserInfo']),
			toLogin(){
				this.$router.push({name:'Login'})
			},
			toRegister(){
				this.$router.push({name:'Register'})
			},
			toAboutUs(){
				this.$router.push({name:'AboutUs'})
			},
			toQuest(){
				// this.isRuleShow = false;
				// this.$message('进入问卷页...')
				// setTimeout(() => {
				// 	this.$router.push({name:'Questionnaire'})
				// }, 400);
			},
			nacCli(index){
				if (this.newIndex==index) {
					this.newIndex = index
				}
			},
			toRecharge(){
				this.isRuleShow = false;
				this.$router.push({name:'Recharge'})
			},
			toFirst(){
				if (this.$route.name=='FirstPage') return
				this.newIndex = 0
				this.$router.push({name:'FirstPage'})
			},
			toPersonal(){
				this.$router.push({name:'MyAccount'})
			},
			ruleShowX(ind){
				if (ind<2) {
					// this.ruleShow()
				} else {
					// console.log(this.ruleTips[ind],ind,'ind999',this.ruleTips)
					this.ruleVal =`<p><strong>${this.ruleTips[ind-2].title}</strong></p>${this.ruleTips[ind-2].content}`
					this.ruleShowR()
				}

			},
			hideLayer(){
				if(this.showLayer){
					this.showLayer = false;
				}else{
					this.showLayer = true;
				}

			},
			ruleShowR(){
				this.isRuleShowR = true;
			},
			ruleShow(){
				// this.isRuleShow = true;
			},
			ruleClose(){
				// let num = Number(localStorage.getItem('isnMsg'));
				// num+=1;
				// localStorage.setItem('isnMsg',num);
			},
			isRuleTopCli(ind){
				this.isRuleTopInd = ind;
				this.isRuleTopShow = true;
			},
			handleScroll () {
				// let scrollTop = document.getElementsByClassName('home')[0].scrollTop;
				// console.log('【滚动距离】', document.documentElement.scrollTop);
				this.scrollTopCount = document.documentElement.scrollTop;
				// let ch = document.getElementsByClassName('strategy-box')[0].clientHeight;
				// console.log('【可视区域】', ch);
				// let sh = document.getElementsByClassName('strategy-box')[0].scrollHeight;
				// console.log('【滚动条高度】', sh);
				/*scrollTop + ch = sh*/
			},
			// 点击回到顶部
			clickBackTop(){
					// const box = document.getElementsByClassName('home')[0];
					// var timer = '';
					// timer = setInterval(() => {
					// 	this.scrollTopCount = Math.floor(this.scrollTopCount / 20);
					// 	box.scrollTop=this.scrollTopCount;
					// 	if(this.scrollTopCount == 0){
					// 		clearInterval(timer);
					// 	}
					// }, 100);
					this.$nextTick(()=>{
						document.documentElement.scrollTop = 0;
					})
			},
			getRuleTip(){
				this.currentTabList = ['新注册会员将赠送3天VIP体验卡(10次方案查看,历史方案免费查看)','在赢体育获得的收益，在缴纳8%个人所得税后，用户最高可获得85%的分成！'];
				http.get('home/getnotice').then(res=>{
					if (res.data.code==1) {
						this.ruleTips = res.data.data
						// console.log(this.ruleTipList)
						// let m = res.data.data
						this.ruleTips.forEach(item=>{
							this.currentTabList.push(item.title)
							// this.ruleVal+=`<p><strong>${item.title}</strong></p>${item.content}`
						})

					}

				})
			},
			getNav(){
				setTimeout(() => {
					// console.log(this.$route.path.indexOf('personalCenter'));
					if (this.$route.path.indexOf('personalCenter')!=-1) {
						this.newIndex = (page.length-1)
						return
					}
				     page.forEach((item,index)=>{
				     	if (item.name==this.$route.name) {
				     		this.newIndex = index
				     		return;
				     	}
				     });
				}, 200)
				this.newIndex = null
			},
			//退出登录
			outLogin(){
				http.get('login/logout').then(res=>{
					if (res.data.code==1) {
						// const store = this.$store;
						this.$message.success(res.data.msg);
						this.userStatus = false
						this.userInfo = {}
						// store.dispatch('getUserInfo',{})
						localStorage.clear();
						this.$emit('changeActiveStep',false);
						if (this.$route.name=='MyAccount'||this.$route.name=='MyAttention'||this.$route.name=='MySect'||this.$route.name=='MyPublication'||this.$route.name=='MyGuessBall') {
							this.$router.replace({name:'FirstPage'})
						}else {
							this.$router.go(0)
						}
					}

				})
			},
			changeStep(val){
				// console.log(val,'changeStep');
			    this.userStatus = val
				this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
			},
			IsShow(val){
				// console.log(val,'IsShow');
				this.isShow = val
			},
			getUser(val){
				this.userInfo = val
				this.userStatus = this.userInfo?true:false;
			},
			isWizard(){
				let _this = this;
				let step = 0;
				let comStatus = false;
				this.$intro.onbeforechange(()=>{
				        // 点击下一步导航前的回调
				    })
				    .onchange((e)=>{
				       // 点击下一步导航的回调
					   // console.log(555,this.newIndex);
					   // if(this.newIndex){
						   // console.log(555);
						   // this.$router.push({name:this.navList[this.newIndex++].name})
					   // }
					   step = Number(e.getAttribute('data-step'));


				    }).onafterchange(()=>{
				        // 点击下一步导航后的回调
				    }).oncomplete(function (e) {
						//点击结束按钮后， 执行的事件
						// console.log(3333)
						// console.log(localStorage.getItem('isWizard'))
						// if (localStorage.getItem('isWizard')!=0) {
						// 	_this.$router.push({name:'Tieba'})
						// }
						// comStatus = true;
						// localStorage.setItem('isWizard',0);
						_this.$router.push({name:'Tieba'})
					}).onexit(()=>{//跳过按钮执行的事件
						// console.log(step,2222)
						if (step<9) {
							localStorage.setItem('isWizard',0);
						}
						// else {
						// 	_this.$router.push({name:'Tieba'})
						// }
					})
				this.$intro.setOptions({
				  // nextLabel: '下一步',  // 下一个按钮文字
				  // prevLabel: '上一步',  // 上一个按钮文字
				  // // skipLabel: '跳过',    // 跳过按钮文字
				  doneLabel: '下一步',// 完成按钮文字
				  hidePrev: true,       // 在第一步中是否隐藏上一个按钮
				  // hideNext: false,       // 在最后一步中是否隐藏下一个按钮
				  // exitOnOverlayClick: false,  // 点击叠加层时是否退出介绍
				  // showStepNumbers: false,     // 是否显示红色圆圈的步骤编号
				  // disableInteraction: true,   // 是否禁用与突出显示的框内的元素的交互，就是禁止点击
				  // showBullets: false          // 是否显示面板指示点
				});
				this.$intro.start()
			},
			//获取友情链接
			getLink(){
				http.get('home/getlink').then(res=>{
					if (res.data.code==1) {
						this.linkList = res.data.data
					}

				})
			},
			toMember(){
				this.$router.push({name:'Member'})
			},
      toZsvip(){
      	this.$router.push({name:'Zsvip'})
      },
      toZs(){
        window.open("https://vip.yingtiyu.com/zs/","_blank")
      },
			//跳转友情链接
			toLink(furls){
				window.open(furls,"_blank")
			},
			//获取验证码
			getCode() {
				let userName = this.ruleForm.username
				if (!userName) {
					return this.$message({
						message: '手机号不能为空！',
						type: 'warning'
					});
				}
				http.post('login/getVcode', {
					tel: userName
				}).then(res => {
					if (res.data.code === 1) {
						this.$message.success(res.data.msg)
						this.codeText = this.codeTimes + 's'
						let times = setInterval(() => {
							if (this.codeTimes <= 0) {
								clearInterval(times)
								this.codeText = '获取验证码'
							} else {
								this.codeTimes--
								this.codeText = this.codeTimes + 's'
							}
						}, 1000)
					} else {
						this.$message.error(res.data.msg);
					}
				}).catch(err => {
					console.log(err);
				})
			},
		}
	}
</script>

<style>
#app {
	font-family:"Microsoft YaHei";
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50; */
	/* width: 1903px; */
	min-width: 1300px;
}
body{
	margin: 0;
	padding: 0;
}
.el-popover{
  height: 400px;
  overflow: auto;
  scrollbar-width: none!important;
}
</style>
<style scoped>
	.home {
		overflow: hidden;
	}

	.el-header {
		background: url('@/static/icons/topBg.png') no-repeat;
		background-size: 100% 100%;
		color: #fff;
		line-height: 70px;
		text-align: center;
		display: flex;
		justify-content: center;
		position: fixed;
		width: 100%;
		min-width: 1300px;
		z-index: 3;

	}
	.handleBtn .el-button--mini{
		border-radius: 3px;
	}
	.headerBox {
		width: 1200px;
		line-height: 80px;
		display: flex;
		justify-content: space-between;
		flex-wrap: nowrap;
		position: relative;
	}

	.userK{
		position: absolute;
		right: -10px;
		top:70px;
		width: 340px;
		background-color: #ffffff;
		border-radius: 10px;
		border: #e1e1e1 1px solid;
	}
	.userK .userKTitle{
		height: 30px;
		line-height: 30px;
		background-color: #FDEBEB;
		color: #FF4349;
		font-size: 14px;
		text-align: left;
		padding-left: 10px;
		font-weight: bold;
		border-radius: 10px 10px 0  0 ;
	}


	.userKInfo {
		display: flex;
		padding-left: 25px;
		padding-top: 25px;
		line-height: 50px;
	}

	.userKInfo .Avatar {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		margin: auto;
	}
	.userKInfo .userKName {
		color: #000000;
		line-height: 25px;
		font-size: 16px;
		text-align: left;
		padding-left: 10px;
	}
	.userKInfo .userKName div:nth-child(2){
		color: #a1a1a1;
		font-size: 12px;
	}

	.userKInfo .userCentBtn {
		padding-left: 60px;
		line-height: 40px;

	}
	.userKInfo .userCentBtn .el-button.is-round{
		background-color: #F6F7F9;
		border: none;
	}

	.userK .userKCoin{
		color: #bf720c;
		    font-size: 24px;
		    display: flex;
		    justify-content: space-evenly;
		    line-height: 12px;
		    border: #eea951 1px solid;
		    border-top: none;
		    border-radius: 0 0 10px 10px;
		    width: 298px;
		    margin: 0 auto;
		    height: 56px;
		    align-items: center;
		    padding-top: 10px;
        font-weight: bold;

	}
	.userK .userKCoin div{
		position: relative;

	}
	.userK .userKCoin .el-divider--vertical{
		padding:0;
    height: 50px;
	}
	.userK .userKCoin div p{
		font-size: 14px;
	}
	.userK .userKCoin div span{

		font-size: 14px;
	}

	.userK .userBtn{
		padding: 0 20px;
		display: flex;
		justify-content: space-between;
		line-height: 10px;
	}

	.userK .userBtn div .el-button{
		background: linear-gradient(90deg,#3588EE,#297EE9,#246DDC,#1A5FD6);
		color:#FFFFFF;
		font-size: 15px;
		font-weight: bold;
		padding: 5px 20px;
	}
	.userK .userExit {
		line-height: 40px;
		color:#a1a1a1;
		text-align: right;
		font-size: 12px;
		padding-right: 30px;
		cursor: pointer;

	}
  .vipCenter {
    margin-top: 10px;
  }
  .vipCenter .zsvipCenter{
    border-radius: 10px  10px 0 0;
  }
  .vipCenter .zj{
    background: linear-gradient(90deg,#eba03d,#efad59);
  }
  .vipCenter .zsvipRight .zj{
    background: #b67a2d;
  }

  .zsvipCenter {
    width:300px;
    height: 88px;
    background: linear-gradient(90deg,#0f59d8,#2f74e8);
    border-radius: 10px;
    color: #FFFFFF;
    margin:  0 auto;
    line-height: 43px;

  }
  .zsvipLeft{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
     padding:0 20px;
  }
  .vipTitle{
    font-size: 22px;
    font-weight: bold;
    width: 120px;
    text-align: center;
  }
  .futitle {
    font-size: 16px;
    color: #cccccc;
  }
  .futitleL{
    font-size: 14px;
    color: #cccccc;
  }
   .zsvipRight{
     display: flex;
     flex-wrap: wrap;
     justify-content: space-between;
     align-items: center;
     padding:0 20px;

   }
   .vipBtn {
     width: 120px;
     height: 30px;
     background-color: #134baa;
     border-radius: 15px;
     text-align: center;
     line-height: 30px;
     font-size: 16px;
     cursor: pointer;
   }

	.el-footer {
		background: url('@/static/icons/footBg.png') no-repeat;
		background-size: 100% 100%;
		color: #0D0D0D;
		text-align: center;
		height: 160px!important;
		min-width: 1300px;
		font-size: 13px;
		padding-top: 20px;
		box-sizing: border-box;
	}
	.el-footer div {
		margin-top: 10px;
	}
	.el-footer div:first-child {
		margin-top: 0;
	}
	.el-footer div:last-child {
		margin-top: 5px;
	}
	.el-footer div:nth-child(4) {
		margin-top: 15px;
	}
	.el-footer /deep/ .el-divider--vertical {
		width: 1px;
		height: 10px;
		background: #333333;
		margin: 0 8px;
	}
	.el-footer div .about {
		cursor: pointer;
		margin-right: 10px;
		text-decoration: underline;
	}
	.el-footer div .about:hover {
		color: #0A54D6;
	}
	.el-footer .alinks {
		display: flex;
		align-items: center;
		flex-wrap: nowrap;
		justify-content: center;
	}
	.el-footer .alinks a:hover {
		cursor: pointer;
	}
	.el-footer .alinks span {
		display: flex;
		align-items: center;
	}

	.el-main {
		background-color: #e9eef3;
		color: #333;
		text-align: center;
		line-height: 160px;
		padding: 0;
		margin-top: 70px;
		overflow:visible;
	}

	.homeLogo {
		width: fit-content;
		height: 70px;
	}

	.homeLogo img {
		width: 163px;

		margin-top: 13px;
		image-rendering: -webkit-optimize-contrast; /* 处理模糊代码 */
	}

	.el-header a {
		text-decoration: none;
		color: #fff;
	}

	.homeTags {
		width: 611px;
		line-height: 28px;
		overflow: hidden;
		padding-top: 30px;
	}

	.homeTags span {
		float: left;
		padding-right: 64px;
	}

	.homeTags span:last-child {
		padding-right: 0;
	}
	.homeTags span.isNav a {
		padding-bottom: 2px;
		border-bottom: #fff 2px solid;
	}

	.index_right {
		/* width: 160px; */
		/* height: 15px; */
		/* margin: auto 0; */
		padding-top: 10px;
	}

	.index_right div {
		height: 15px;
		line-height: 15px;
	}

	.index_right .handleBtn{

		text-align: right;
		font-size: 13px;
		margin-top: 23px;
		cursor: pointer;
		display: flex;
		width: 100px;
		justify-content: space-between;
		line-height: 30px;
	}

	.index_right .userBtn{
		line-height: 19px;
		text-align: right;
		font-size: 13px;
		margin-top: 30px;
		cursor: pointer;
	}




	.index_right .userBtn a:hover {
		text-decoration: underline;
		cursor: pointer;
	}

	.ruleShow /deep/ .el-dialog {
		/* height: 500px; */
		overflow: auto;
		background: transparent;
		box-shadow: none;
	}
	.ruleShow /deep/ .el-dialog__body {
		color: #000;
		line-height: 22px;
		text-align: left;
		font-size: 15px;
		padding: 0;

	}
	.ruleShow /deep/ .el-dialog__body p:first-child {
		font-size: 18px;
	}
	.ruleShow /deep/ .el-dialog__header {
		padding: 0;
	}
	.ruleShow /deep/ .el-dialog__footer {
		padding: 0 20px;
		line-height: 22px;
		margin-bottom: 20px;
	}
	.ruleShow.isRuleTopShow /deep/ .el-dialog{
		max-height: 50vh;
		overflow: auto;
		background: #ffff;
		padding: 30px 20px;
		box-shadow: 0 1px 3px rgba(0,0,0,.3);
	}
	.ruleShow /deep/ .el-dialog__header .el-dialog__headerbtn {
		/* right: 0!important; */
	}
	.ruleShow /deep/ .el-dialog__header .el-dialog__headerbtn .el-dialog__close {
		/* color: #fff!important; */
    	font-size: 32px;
	}


	.returnTop {
		position: fixed;
		right: calc((100vw - 1360px)/2);
		bottom: 100px;
		width: 40px;
		background: #FFF;
		z-index: 101;
		box-shadow: 0 1px 10px 0 rgb(0 0 0 / 6%);
	}
	.returnTop div {
		height: 40px;
		width: 40px;
		margin-bottom: -1px;
		display: block;
		float: left;
		cursor: pointer;
		border: #efefef 1px solid;
		border-radius: 2px;
		position: relative;
	}
	.returnTop div img {
		width: 32px;
		height: 32px;
		margin: 5px;
	}
	.returnTop div .contactCode {
		background: #fff;
		width: 250px;
		height: 341px;
		padding: 10px 5px;
		border-radius: 4px;
		position: absolute;
		right: 42px;
		/* top: -5px; */
		bottom: 0;
		box-shadow: 0 1px 10px rgba(0,0,0,0.2);
	}
	.returnTop div .contactCode img {
		width: 240px;
		height: 341px;
		image-rendering: -webkit-optimize-contrast; /* 处理模糊代码 */
	}
	.returnTop div:nth-child(2):hover .contactCode {
		display: block!important;;
	}
</style>
